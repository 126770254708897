import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LeKelAaAAAAAKxUMbrI8OHHSEiB3u8AfZWu8BKu">
            {element}
        </GoogleReCaptchaProvider>
    )
}
